import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import Layout from "../components/layout/layout";

// markup
const IndexPage = () => {
  return (
    <Layout title="Lovely Ladyverse - The Ladyverse">
      <h1 class="flex-pageheading">The Lovely Ladyverse</h1>
      <StaticImage src="../images/ourlovelyladyverse.png" alt="Our Lovely Ladyverse" />
      <p class= "flex-textbox">The Great War is over, but its booming shells still echo in our psyche. A constant drone like tinnitus. The Rabies pandemic may have ended, but blood still stains our ripped wallpaper. Cracked teeth and foaming maws. The old world is dead, and the promised new world said it was "Just popping out back for a fag" and then never came back. It is the early 20th century, and the world teeters on the tightrope like a sad clown. Fall to one side and burn in atomic fire, fall to the other and drown in salt and seaweed. Above is, like daggers frozen in time, hang The Thirteen. Between these fates, on the tightrope itself, there is the one thing that makes the effort of balancing worth it: theres women.</p>
    </Layout>
  );
};

export default IndexPage;
